import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import styles from '../styles';
import { appNames } from '../../constants';
import { HeaderOTCQX, HeaderOTCQB, HeaderDNS } from '../headers';
import { Footer } from '../components';
import Cover from '../pages/Cover';
import SignSubmit from '../pages/SignSubmit';

const OTCQX_US = appNames.OTCQX_US;
const OTCQX_BANKS = appNames.OTCQX_BANKS;
const OTCQX_INTL = appNames.OTCQX_INTL;
const OTCQB = appNames.OTCQB;
const DNS = appNames.DNS;

const TemplateSignature = ({ application, owners, appStates }) => {
  const status = application && application.status;
  const appStateObj = appStates && appStates.find(state => state.stateId === status);
  const statusDesc = appStateObj?.description || application.status;
  const symbol = application && application.primarySymbol;
  const companyName = application && application.companyInfo && application.companyInfo.name;
  const appId = application.typeId;

  const renderHeader = _ => {
    switch (appId) {
      case 1:
        return <HeaderOTCQX type={OTCQX_US} />;
      case 2:
        return <HeaderOTCQX type={OTCQX_BANKS} />;
      case 3:
        return <HeaderOTCQX type={OTCQX_INTL} />;
      case 4:
        return <HeaderOTCQB />;
      case 5:
        return <HeaderDNS />;
      default:
        return <HeaderOTCQX type={OTCQX_US} />;
    };
  };

  const renderCover = _ => {
    switch (appId) {
      case 1:
        return <Cover type={OTCQX_US} status={statusDesc} symbol={symbol} symbolType={'OTCQX'} companyName={companyName} owners={owners} people={application.peopleList} />;
      case 2:
        return <Cover type={OTCQX_BANKS} status={statusDesc} symbol={symbol} symbolType={'OTCQX'} companyName={companyName} owners={owners} people={application.peopleList} />;
      case 3:
        return <Cover type={OTCQX_INTL} status={statusDesc} symbol={symbol} symbolType={'OTCQX'} companyName={companyName} owners={owners} people={application.peopleList} />;
      case 4:
        return <Cover type={OTCQB} status={statusDesc} symbol={symbol} symbolType={'OTCQB'} companyName={companyName} owners={owners} people={application.peopleList} />
      case 5:
        return <Cover type={DNS} status={statusDesc} symbol={symbol} symbolType={'OTCID'} companyName={companyName} owners={owners} people={application.peopleList} />
      default:
        return <Cover type={OTCQX_US} status={statusDesc} symbol={symbol} symbolType={'OTCQX'} companyName={companyName} owners={owners} people={application.peopleList} />;
    };
  };

  return (
    <Document style={styles.document} >
      <Page style={styles.page} size='A4'>
        {renderHeader()}
        {renderCover()}
        <Footer companyName={companyName} hidePageNumber />
      </Page>
      <Page style={styles.page} size='A4'>
        {renderHeader()}
        <SignSubmit data={application.signSubmit} eligibilityStandards={application.eligibilityStandards} submitDate={application.submitDate} />
        <Footer companyName={companyName} hidePageNumber />
      </Page>
    </Document>
  );
};

TemplateSignature.propTypes = {
  application: PropTypes.object,
  owners: PropTypes.array,
  appStates: PropTypes.array
};

export default TemplateSignature;
