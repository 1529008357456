import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const getCompanyStyle = (companyName) => {
  let companyNameStyle = { fontWeight: 600, fontSize: '48px' };
  if (companyName) {
    const len = companyName.length;
    if (len > 40) {
      companyNameStyle.fontSize = '16px';
    } else if (len > 30) {
      companyNameStyle.fontSize = '24px';
    } else if (len > 20) {
      companyNameStyle.fontSize = '36px';
    }
  }
  return companyNameStyle;
};

const Cover = ({ type, status, symbol, symbolType, companyName, owners, people }) => {
  const appOwners = owners && owners.filter(owner => !owner.id.isRemoved);
  const primaryContacts = people && people.filter(item => item.isPrimaryContact);
  const statuStyle = `status_${type}`;
  const displaySymbol = symbol && symbolType ? symbolType + ':' + symbol : 'New security';
  const appStatus = status && status.toUpperCase();

  ; return (
    <View>
      <View style={styles.header}>
        <Text style={getCompanyStyle(companyName)}>
          {companyName}
        </Text>
        <Text style={styles.subHead}>
          <Text style={styles[statuStyle]}>{appStatus}</Text> | ({displaySymbol})
        </Text>
      </View>
      <View style={styles.info}>
        <View style={styles.title}>
          <SectionTitle style={styles.title} text='APPLICATION OWNERS' />
        </View>
        {appOwners && appOwners.map((owner, i) => {
          return (
            <View key={`app-owner-${i}`}>
              <Text style={styles.name}>
                {`${owner.fullName}, ${owner.affiliation}`}
              </Text>
              <Text style={styles.email}>
                {owner.id.email}
              </Text>
              <View style={styles.divider} />
            </View>
          );
        })}
      </View>
      <View style={styles.info}>
        <View style={styles.title}>
          <SectionTitle style={styles.title} text='Primary Contacts' />
        </View>
        {primaryContacts && primaryContacts.map((contact, i) => {
          const contactTitle = contact && contact.title1 ? `, ${contact.title1}` : '';
          const fullName = renderFullName(contact.firstName, contact.middleName, contact.lastName);

          return (
            <View key={`prim-contact-${i}`}>
              <Text style={styles.name}>
                {`${fullName || ''}${contactTitle}`}
              </Text>
              <Text style={styles.phone}>
                {contact.phone}
              </Text>
              <Text style={styles.email}>
                {contact.email}
              </Text>
              <View style={styles.divider} />
            </View>
          );
        })}
        {(!primaryContacts || primaryContacts.length === 0) && <Value text={null} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingTop: 40
  },
  subHead: {
    fontSize: '16px',
    fontWeight: 700
  },
  status_otcqxus: {
    color: '#00a88f'
  },
  status_otcqxintl: {
    color: '#00a88f'
  },
  status_otcqxbanks: {
    color: '#00a88f'
  },
  status_otcqb: {
    color: '#F7931D'
  },
  status_dns: {
    color: '#77787B'
  },
  title: {
    marginBottom: 8
  },
  info: {
    marginTop: 24
  },
  person: {
    position: 'relative',
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  name: {
    fontSize: 12,
    fontWeight: 400,
    // flex: '6'
  },
  phone: {
    fontSize: 12,
    fontWeight: 200,
    // flex: '1',
    minWidth: 100
  },
  email: {
    fontSize: 12,
    fontWeight: 200,
    // flex: '1',
    minWidth: 100

  },
  divider: {
    margin: '8 0',
    borderBottom: '1px solid #D4D4D4'
  }
});

export default Cover;
