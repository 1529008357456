import React, { useEffect, useState } from 'react';
import TabMenu from '../../components/TabMenu';
import ChangePassword from '../ChangePassword';
import ManageMFA from '../ManageMFA';
import { withRouter } from '../../components/WithRouter';
import styles from './AccountSettings.module.scss';

const tabItems = [
  {
    title: 'Change Password',
    path: 'change-password',
    component: ChangePassword
  },
  {
    title: 'Manage PIN',
    path: 'manage-pin',
    component: ManageMFA
  }
];

const AccountSettings = ({ navigate, match: { params: { settingsTab } } }) => {
  const currentTab = tabItems.find(item => item.path === settingsTab);
  const [activeTab, setActiveTab] = useState(currentTab || tabItems[0]);

  useEffect(() => {
    const newTab = tabItems.find(item => item.path === settingsTab);
    setActiveTab(newTab);
  }, [settingsTab]);

  useEffect(() => {
    activeTab && navigate(`/account-settings/${activeTab.path}`);
  }, [activeTab]);

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const renderTab = () => {
    const Component = activeTab.component;
    return <Component />;
  };

  return <div className={styles.container}>
    <h1 className={styles.title}>
      Account Settings
    </h1>
    {activeTab && <>
      <TabMenu items={tabItems} activeTab={activeTab.title} handleTabClick={handleTabClick} />
      {renderTab()}
    </>}
  </div>;
};

AccountSettings.propTypes = {

};

export default withRouter(AccountSettings);
