import React, { useContext, useState, useEffect } from 'react';
import cn from 'classnames';
import { TypesContext } from '../../context/Types.context';
import Field from './Field';
import Item from './Item';
import TextBox from '../TextBox';
import SelectBox from '../SelectBox';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { errors } from '../../constants';
import { isPhone } from '../../utils/validations';
import styles from './PhoneInput.module.scss';

const US = 'US';
const rules = { 'X': /[02-9]/ };

const PhoneInputField = ({ label, phoneValue, readOnly, showCountryCode, phoneNumberField, countryCodeValue, countryCodeField, disabled, onValueChanged, isRequired }) => {
  const [typesState] = useContext(TypesContext);
  const [isFocus, setFocus] = useState(false);
  const [countryCode, setCountryCode] = useState(countryCodeValue);
  const countries = (typesState && typesState.codes && typesState.codes.countries) || [];
  const isPhoneValid = isPhone(phoneValue);
  let errorText = '';
  const phoneCode = countryCode && countries && countries.find(country => country.id === countryCode);
  const showRequired = isRequired && (!phoneValue || ((showCountryCode && phoneCode) && !countryCodeValue) || (phoneValue && phoneValue.trim().length === 0));

  if (isRequired) errorText = errors.INCOMPLETE_DATA_FIELD;
  if (!isPhoneValid) errorText = 'Invalid Phone Format.';

  useEffect(() => {
    if (showCountryCode && !countryCodeValue) onCountryCodeChange(US);
  }, []);

  const onCountryCodeChange = value => {
    setCountryCode(value);
    onValueChanged(value, countryCodeField || 'phoneCountryCode');
  };

  const onPhoneNumberChange = value => {
    if (showCountryCode && !countryCodeValue) {
      setTimeout(() => {
        if (!countryCodeValue) onValueChanged(US, countryCodeField || 'phoneCountryCode');
      }, 1000);
    }
    onValueChanged(value, phoneNumberField || 'phoneNumber');
  };

  return (
    <div>
      <div>
        {label && <FieldLabel text={label} />}
        {(showRequired || !isPhoneValid) && <FieldError isFocus={isFocus} error={errorText} />}
      </div>
      <div className={cn(styles.container, 'phone-select', {
        'hasCountryCode': (showCountryCode && phoneCode)
      })}>
        {(showCountryCode && phoneCode) && <>
          <SelectBox id='custom-templates'
            items={countries}
            displayExpr='name'
            valueExpr='id'
            width='50px'
            disabled={readOnly || disabled}
            dropDownOptions={{
              'minWidth': '300px'
            }}
            fieldRender={Field}
            itemRender={Item}
            isRequired={showRequired}
            value={countryCodeValue}
            onValueChanged={e => onCountryCodeChange(e.value)} />
          <div className={cn(styles.staticCode, {
            [styles.isFocus]: isFocus,
            [styles.disabled]: readOnly || disabled
          })}>
            +{phoneCode && phoneCode.phoneCode}
          </div>
        </>}
        <div className={styles.input}>
          <TextBox
            placeholder='Phone Number'
            maskRules={rules}
            value={phoneValue}
            disabled={readOnly || disabled}
            isRequired={showRequired}
            showClearButton
            isIncomplete={!isPhoneValid}
            onFocusIn={() => setFocus(true)}
            onFocusOut={() => setFocus(false)}
            onValueChanged={e => onPhoneNumberChange(e.value)} />
        </div>
      </div>
      <abbr>Phone must begin with country code. e.g: '+1'</abbr>
    </div>
  );
};

PhoneInputField.defaultProps = {
  isValid: true,
  showCountryCode: false
};

export default PhoneInputField;
