import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import { logoutSession } from '../../api/auth';
import { routes, titles } from '../../constants';
import { AuthContext } from '../../context/Auth.context';
import SlidePanel from '../SlidePanel';
import HelpSection from '../HelpSection';
import styles from './Avatar.module.scss';

const Avatar = props => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [slidePane, setSlidePane] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleSignout = () => {
    logoutSession();

    authDispatch({
      type: 'SIGN_OUT_USER'
    });
  };

  const handleHelpClick = () => {
    setShowDropdown(false);
    setSlidePane(true);
  };

  return (
    <div className={styles.container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={styles.avatar}>
        {props.initials}
      </div>
      {showDropdown && <div className={styles.dropDown}>
        <div className={styles.options}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <Link to={routes.CHANGE_PASSWORD}>
                Change Password
              </Link>
            </li>
            <li className={styles.item}>
              <Link to={routes.MANAGE_MFA}>
                Manage PIN
              </Link>
            </li>
            <li className={styles.item}>
              <span onClick={handleHelpClick}>Help</span>
            </li>
          </ul>
          <div className={styles.signout} onClick={handleSignout}>
            Sign out
          </div>
        </div>
      </div>}
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={titles.HELP}>
        <HelpSection />
      </SlidePanel>
    </div>
  );
};

export default withRouter(Avatar);
