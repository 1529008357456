import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import LOGO_DNS from './LOGO_DNS';

const HeaderDNS = () => (
  <View style={styles.header} fixed>
    <View style={styles.headerImg}>
      <LOGO_DNS />
    </View>
  </View>
);

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    display: 'block',
    backgroundColor: '#77787B',
    left: 0,
    top: 0,
    right: 0,
    width: '100vw',
    height: '72px'
  },
  headerImg: {
    position: 'absolute',
    top: 30,
    left: 72
  }
});

export default HeaderDNS;
