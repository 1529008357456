import {
  signIn,
  signUp,
  signOut,
  confirmSignUp,
  getCurrentUser,
  resendSignUpCode,
  confirmSignIn,
  fetchUserAttributes
} from 'aws-amplify/auth';
import api from './api';

export const validateSession = async (token) => {
  const response = await api.request({
    method: 'get',
    url: '/session-token/',
    params: { token }
  });
  return response.data;
};

export const logoutSession = async () => {
  const response = await api.request({
    method: 'post',
    url: '/session-token/log-out'
  });
  return response.data;
};

export const awsSignUp = async (user) => {
  const response = await signUp(user);
  return response;
};

export const awsConfirmSignUp = async (user, code) => {
  const response = await confirmSignUp({
    username: user,
    confirmationCode: code
  });
  return response;
};

export async function signInAWS (user, password) {
  const response = await signIn({ username: user, password });
  return response;
}

export const signInIQ = async (user) => {
  user.email = user.email?.toLowerCase();
  const response = await api.request({
    method: 'post',
    url: '/user/',
    data: user
  });
  return response.data;
};

export const getCurrentAwsUser = async () => {
  const response = await getCurrentUser();
  return response;
};

export const getExistingIQUser = async (email, getIqUrl) => {
  const response = await api.request({
    method: 'get',
    url: '/user/',
    params: { email, iqUrl: getIqUrl }
  });
  return response.data;
};

export const resendAwsVerify = async (user) => {
  const response = await resendSignUpCode(user);
  return response;
};

export const resendAwsMFA = async (user) => {
  const response = await resendSignUpCode(user);
  return response;
};

export const resendIQMFA = async (userId) => {
  const response = await api.request({
    method: 'post',
    url: '/user/challenge',
    params: { userId, pinCodeLength: 4 }
  });
  return response.data;
};

export const getUserAttributes = async () => {
  const response = await fetchUserAttributes();
  return response;
};

export const validateAwsChallenge = async (code) => {
  const response = await confirmSignIn({ challengeResponse: code });
  return response;
};

export const validateIQChallenge = async (userId, code, challengeId) => {
  const response = await api.request({
    method: 'post',
    url: '/user/validate',
    params: { userId, pin: code, challengeId }
  });
  return response.data;
};

export const awsSignout = async () => {
  const response = await signOut();
  return response;
};

export const logUser = async (email) => {
  const ipData = await api.request({
    method: 'get',
    url: 'https://api.ipify.org/?format=json'
  });

  const ipAddress = ipData?.data?.ip;

  if (!ipAddress) return;

  await api.request({
    method: 'get',
    url: '/user/login-log',
    params: { email, appId: 'GW', ipAddress: ipAddress.toString() }
  });
};
