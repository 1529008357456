import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import equal from 'fast-deep-equal/es6';
import { pdf } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appStates, errors, routes, timezones, paymentTypes, achPayments, onlinePayments, offlinePaymentTypes, urls } from '../../constants';
import { isSubmitReady, paymentCheckComplete, applicationSectionsComplete } from '../../constants/cardComplete';
import { applications } from '../../constants/applications';
import { AuthContext } from '../../context/Auth.context';
import { TypesContext } from '../../context/Types.context';
import { ApplicationContext } from '../../context/Application.context';
import { NotificationsContext } from '../../context/Notifications.context';
import { TemplateOTCQXUS, TemplateOTCQXBanks, TemplateOTCQB, TemplateDNS, TemplateOTCQXIntl, TemplateSignature } from '../../pdfTemplates/templates';
import StartApplicationDisclaimer from '../../components/StartApplicationDisclaimer';
import ApplicationNav from '../../components/ApplicationNav';
import ApplicationTitle from '../../components/ApplicationTitle';
import Title from '../../components/Title';
import Label from '../../components/Label';
import Controls from '../../components/Controls';
import GlobalActions from '../../components/GlobalActions';
import { withRouter } from '../../components/WithRouter';
import { sendAchPayment, sendCreditCardPayment } from '../../api/payment';
import { getApplication, getApplicationOwners, updateApplication, submitApplication, uploadApplicationPDF } from '../../api/application';
import { getApplicationDocs } from '../../api/document';
import { getQueryObj } from '../../utils/helper';
import { isBoolean } from '../../utils/validations';
import ProcessingOverlay from '../../components/ProcessingOverlay';
import Entity from '../../components/Entity';
import styles from './ApplicationPage.module.scss';

const ApplicationPage = ({ navigate, location: { search }, match: { params: { appId, appSection, appTab } } }) => {
  const query = getQueryObj(search);
  const [isStartScreen, setStartScreen] = useState(query.start === 'true');
  const [authState] = useContext(AuthContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [typesState] = useContext(TypesContext);
  const [state, dispatch] = useContext(ApplicationContext);
  const [appType, setAppType] = useState();
  const [totalSections, setTotalSections] = useState();
  const [submitError, setSubmitError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const backupApplication = { ...state.backupApplication };
  const application = state.application;
  const sessionPayment = state.sessionPayment;
  const documents = state.documents;
  const docTypes = typesState.docTypes;
  const owners = state && state.owners;
  const appStatus = application && application.status;
  const completedCards = state.completeCards;
  const isOTCQXUS = application && application.typeId && application.typeId === 1;
  const isOTCQXBanks = application && application.typeId && application.typeId === 2;
  const isOTCQXIntl = application && application.typeId && application.typeId === 3;
  const isOTCQB = application && application.typeId && application.typeId === 4;
  const isDNS = application && application.typeId && application.typeId === 5;
  const appStatesTypes = typesState.appStates;
  const [error, setError] = useState(null);
  const userEmail = authState.email;
  const sections = application && appType && Array.isArray(appType.sections);
  const hideCancel = sections && ((appType.sections[0].path === appSection) || (appType.sections[appType.sections.length - 1].path === appSection));
  const isSubmitSection = appSection && appSection === routes.PAYMENT_SUBMIT;
  const isOfflinePayment = application && application.payment && application.payment.paymentType && (application.payment.paymentType === paymentTypes.OFFLINE_CHECK || application.payment.paymentType === paymentTypes.OFFLINE_WIRE);
  const showSubmit = appStatus === appStates.IN_PROGRESS && (isSubmitSection && isSubmitReady(completedCards)) || !isSubmitSection;
  const submitOnly = isOfflinePayment || (application && application.payment && application.payment.reducedFee === 0) || (application && application.payment && application.payment.paymentDate && !application.submitDate);
  const showPaymentReceipt = application && application.payment && onlinePayments.includes(application.payment.paymentType);
  let submitText = 'Next';

  if (isSubmitSection) submitText = submitOnly ? 'Submit Application' : 'Process Payment and Submit Application';

  useEffect(() => {
    (state.isLoaded && isStartScreen) && setTypes();
    (!state.isLoaded || !isStartScreen) && loadApplication();

    return () => {
      dispatch({
        type: 'REMOVE_APPLICATION'
      });
    };
  }, []);

  useEffect(() => {
    application && checkAppChanges();

    if (setSubmitError) setSubmitError(false);
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }, [appSection]);

  useEffect(() => {
    appType && appType.sections && setTotalSections(appType.sections.length);
  }, [appType]);

  const setTypes = (app = application) => {
    setAppType(applications[typesState.appTypes.find(type => type.id === app.typeId).typeId]);
  };

  const loadApplication = () => {
    getApplication(appId, userEmail)
      .then(d => {
        if (d.data.status === appStates.IN_PROGRESS) {
          if (d.data.typeId === 5) {
            d.data.typeDesc = 'OTCID - Disclosure & News Service'; // remove later
          }
          setTypes(d.data);
          dispatch({
            type: 'SET_APPLICATION',
            payload: d.data,
            original: d.backup
          });

          loadDocuments(appId);
          loadOwners();
        } else {
          navigate(routes.MY_APPLICATIONS);
        }
      })
      .catch(e => setError(e && e.response && e.response.data && e.response.data.message || e.message || errors.TRY_AGAIN));
  };

  const loadDocuments = id => {
    getApplicationDocs(id, userEmail)
      .then(d => {
        const list = d.filter(doc => doc && !doc.isDeleted);
        dispatch({
          type: 'SET_DOCUMENTS',
          payload: list
        });
      })
      .catch(e => setError(e && e.response && e.response.data && e.response.data.message || e.message || errors.TRY_AGAIN));
  };

  const loadOwners = () => {
    getApplicationOwners(appId, userEmail)
      .then(owners => {
        dispatch({
          type: 'SET_OWNERS',
          payload: owners
        });
      })
      .catch(e => console.error(e));
  };

  const handleBackClick = () => {
    const getIndex = item => item.path === appSection;
    const index = appType.sections.findIndex(getIndex);
    let appPath = appType.sections[index - 1].path;

    if (appTab && appType.sections[index].tabs) {
      const tabs = appType.sections[index].tabs;
      const getTabIndex = tab => tab === appTab;
      const tabIndex = tabs.findIndex(getTabIndex);
      if (tabIndex > 0) appPath = `${appSection}/${tabs[tabIndex - 1]}`;
    }

    navigate(`/application/${appId}/${appPath}`);
  };

  const handleNextClick = () => {
    const getIndex = item => item.path === appSection;
    const index = appType.sections.findIndex(getIndex);
    let appPath = appType.sections[index + 1].path;

    if (appTab && appType.sections[index].tabs) {
      const tabs = appType.sections[index].tabs;
      const getTabIndex = tab => tab === appTab;
      const tabIndex = tabs.findIndex(getTabIndex);
      if (tabIndex < (tabs.length - 1)) appPath = `${appSection}/${tabs[tabIndex + 1]}`;
    }

    navigate(`/application/${appId}/${appPath}`);
  };

  const handleSubmitApp = () => {
    if (setSubmitError) setSubmitError(false);
    if (isSubmitReady(completedCards) && paymentCheckComplete(sessionPayment)) {
      setIsSubmitting(true);
      if (achPayments.includes(sessionPayment.paymentType)) submitPaymentAch();
      if (sessionPayment.paymentType === paymentTypes.CREDIT) submitPaymentCreditCard();
      if (offlinePaymentTypes.includes(sessionPayment.paymentType)) submitOfflinePayment();
    } else {
      setSubmitError(errors.SUBMIT_MISSING_FIELDS);
    }
  };

  const submitPaymentAch = () => {
    if (error) setSubmitError(null);
    const achPayload = { ...sessionPayment, appId: application.id };
    sendAchPayment(achPayload, application)
      .then(achDataResponse => {
        let updatePayment = { ...sessionPayment, ...achDataResponse };
        updatePayment.paymentType = sessionPayment.accountType;
        updatePayment.paymentDate = moment().tz(timezones.NEW_YORK).valueOf();
        submitAppWithPayment(updatePayment);
      })
      .catch(e => {
        // payment failed
        setIsSubmitting(false);
        let error = (e && e.response && e.response.data && e.response.data.responseMsg) || e.message || errors.SUBMIT_PAYMENT_FAIL;
        error = <>{error}. {errors.TRY_AGAIN}</>;
        setSubmitError(error);
      });
  };

  const submitPaymentCreditCard = () => {
    if (error) setError(null);
    const ccPayload = { ...sessionPayment, appId: application.id };
    sendCreditCardPayment(ccPayload, application)
      .then(ccDataResponse => {
        let updatePayment = { ...sessionPayment, ...ccDataResponse };
        updatePayment.paymentType = paymentTypes.CREDIT;
        updatePayment.paymentDate = moment().tz(timezones.NEW_YORK).valueOf();
        submitAppWithPayment(updatePayment);
      })
      .catch(e => {
        // payment failed
        setIsSubmitting(false);
        let error = (e && e.response && e.response.data && e.response.data.responseMsg) || e.message || errors.SUBMIT_PAYMENT_FAIL;
        error = <>{error}. {errors.TRY_AGAIN}</>;
        setSubmitError(error);
      });
  };

  const submitOfflinePayment = () => {
    let updatePayment = { ...sessionPayment };
    updatePayment.paymentDate = moment().tz(timezones.NEW_YORK).valueOf();
    submitAppWithPayment(updatePayment);
  };

  const submitAppWithPayment = payment => {
    const cardsCompleted = applicationSectionsComplete({ ...application, payment }, documents, completedCards);
    const sectionComplete = Object.keys(cardsCompleted).filter(key => cardsCompleted[key]).length;
    const newVersion = application.version + 1;
    submitApplication(appId, userEmail, { ...application, payment, totalSections, sectionComplete, version: newVersion })
      .then(d => {
        uploadPDF(d && d.data);
      })
      .catch(e => {
        // setIsSubmitting(false);
        const id = new Date().getTime();
        updateApplication(userEmail, { ...application, payment, totalSections, sectionComplete, version: newVersion })
          .then(d => {
            dispatch({
              type: 'SET_APPLICATION',
              payload: d.data,
              original: d.backup
            });

            dispatchNotification({
              type: 'ADD_NOTIFICATION',
              payload: {
                id: id,
                description: `Your application has been saved. ${moment().format('MMMM D, YYYY')}`,
                type: 'save'
              }
            });

            setTimeout(() => {
              dispatchNotification({
                type: 'REMOVE_NOTIFICATION',
                id: id
              });
            }, 3000);
          })
          .catch(e => {
            setSubmitError(e.message);
            dispatchNotification({
              type: 'FAILED_SAVE_APPLICATION',
              payload: {
                id: id,
                description: `Your application failed to be saved. ${moment().format('MMMM D, YYYY')}`,
                type: 'error'
              }
            });
          });
      });
  };

  const submitApp = () => {
    const cardsCompleted = applicationSectionsComplete({ ...application }, documents, completedCards);
    const sectionComplete = Object.keys(cardsCompleted).filter(key => cardsCompleted[key]).length;
    const newVersion = application.version + 1;
    setIsSubmitting(true);
    submitApplication(appId, userEmail, { ...application, totalSections, sectionComplete, version: newVersion })
      .then(d => {
        uploadPDF(d && d.data);
      })
      .catch(e => {
        setIsSubmitting(false);
        setSubmitError(e.message);
      });
  };

  const handleStartApp = () => {
    setStartScreen(false);
  };

  const checkAppChanges = () => {
    if (application && !equal(backupApplication, application)) {
      saveApplication();
    }
  };

  const saveApplication = (completed) => {
    const id = new Date().getTime();
    const cardsCompleted = applicationSectionsComplete(application, documents, completedCards);
    let sectionComplete = cardsCompleted ? Object.keys(cardsCompleted).filter(key => cardsCompleted[key]).length : 0;
    const newVersion = application.version + 1;
    if (completed) sectionComplete = completed;
    updateApplication(userEmail, { ...application, totalSections, sectionComplete, version: newVersion })
      .then(d => {
        dispatch({
          type: 'SET_APPLICATION',
          payload: d.data,
          original: d.backup
        });

        dispatchNotification({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: id,
            description: `Your application has been saved. ${moment().format('MMMM D, YYYY')}`,
            type: 'save'
          }
        });

        setTimeout(() => {
          dispatchNotification({
            type: 'REMOVE_NOTIFICATION',
            id: id
          });
        }, 3000);
      })
      .catch(e => {
        dispatchNotification({
          type: 'FAILED_SAVE_APPLICATION',
          payload: {
            id: id,
            description: `Your application failed to be saved. ${moment().format('MMMM D, YYYY')}`,
            type: 'error'
          }
        });
      });
  };

  const getAppSection = () => {
    if (!appSection) navigate(`/application/${appId}/${appType.sections[0].path}`, { replace: true });
    const app = appType.sections && appType.sections.find(section => section.path === appSection);
    const appPath = app && app.path;
    const name = app && app.name;
    const Component = app ? app.component : appType.sections[0].component;

    if (app && app.tabs && !appTab) navigate(`/application/${appId}/${appPath}/${app.tabs[0]}`);

    return <Component name={name} company={application.companyInfo.name} saveApplication={saveApplication} />;
  };

  const uploadPDF = async data => {
    const eligibilityStandards = data?.eligibilityStandards;
    const hasInitialReviewAddendum = isBoolean(eligibilityStandards?.hasPricedQuotation) && !eligibilityStandards?.hasPricedQuotation && isBoolean(eligibilityStandards?.hasForm211BidPrice) && !eligibilityStandards?.hasForm211BidPrice && !!eligibilityStandards?.hasInitialReview;
    let ApplicationPdfTemplate;

    if (isOTCQXUS) ApplicationPdfTemplate = TemplateOTCQXUS;
    if (isOTCQXBanks) ApplicationPdfTemplate = TemplateOTCQXBanks;
    if (isOTCQXIntl) ApplicationPdfTemplate = TemplateOTCQXIntl;
    if (isOTCQB) ApplicationPdfTemplate = TemplateOTCQB;
    if (isDNS) ApplicationPdfTemplate = TemplateDNS;

    const uid = data?.uid;
    let filename = uid;
    const errorFileName = `ERROR-${data.id}`;

    if (uid) {
      data.status = 'Submitted (Received)';
    } else {
      data.status = 'Submitted (Failed)';
      filename = errorFileName;
    }

    const applicationBlob = await pdf(
      <ApplicationPdfTemplate docTypes={docTypes} application={data} documents={documents} owners={owners && owners} appStates={appStatesTypes} />
    ).toBlob();

    const uploadApplicationPromise = uploadApplicationPDF(applicationBlob, `${filename}.pdf`);

    const signatureBlob = await pdf(
      <TemplateSignature
        application={data}
        owners={owners && owners}
        appStates={appStatesTypes}
      />
    ).toBlob();

    const customPdfBytes = await signatureBlob.arrayBuffer();

    // Fetch the external PDF
    const agreementPdfUrl = urls.ISSUER_SERVICE_AGREEMENT;
    const addendumPdfUrl = urls.INITIAL_REVIEW_ADDENDUM;
    const agreementPdfBytes = await fetch(agreementPdfUrl).then(res => res.arrayBuffer());
    const addendumPdfBytes = hasInitialReviewAddendum ? await fetch(addendumPdfUrl).then(res => res.arrayBuffer()) : hasInitialReviewAddendum;

    // Load PDFs
    const customPdf = await PDFDocument.load(customPdfBytes);
    const agreementPdf = await PDFDocument.load(agreementPdfBytes);
    const addendumPdf = hasInitialReviewAddendum ? await PDFDocument.load(addendumPdfBytes) : null;

    // Create a new PDFDocument
    const mergedPdf = await PDFDocument.create();

    // Copy pages from the custom PDF
    const [coverPage] = await mergedPdf.copyPages(customPdf, [0]);
    mergedPdf.addPage(coverPage);

    // Copy pages from the agreement PDF
    const [firstPage] = await mergedPdf.copyPages(customPdf, [1]);
    mergedPdf.addPage(firstPage);

    for (let i = 0; i < agreementPdf.getPageCount(); i++) {
      const [agreementPage] = await mergedPdf.copyPages(agreementPdf, [i]);
      mergedPdf.addPage(agreementPage);
    }

    if (hasInitialReviewAddendum) {
      for (let i = 0; i < addendumPdf.getPageCount(); i++) {
        const [addendumPage] = await mergedPdf.copyPages(addendumPdf, [i]);
        mergedPdf.addPage(addendumPage);
      }
    }

    // Copy all remaining pages from the custom PDF
    for (let i = 2; i < customPdf.getPageCount(); i++) {
      const [customPage] = await mergedPdf.copyPages(customPdf, [i]);
      mergedPdf.addPage(customPage);
    }

    // Save the merged PDF
    const mergedPdfBytes = await mergedPdf.save();
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

    const uploadSignaturePdfPromise = uploadApplicationPDF(mergedPdfBlob, `${filename}-isa.pdf`);

    await Promise.all([uploadApplicationPromise, uploadSignaturePdfPromise]);

    dispatch({
      type: 'SET_APPLICATION',
      payload: data,
      original: data
    });

    setSubmitSuccess(true);
  };

  return (
    <div>
      {error && <Label className='mtLg' isError title={error} />}
      {(isStartScreen && application) && <StartApplicationDisclaimer application={application} handleStartApp={handleStartApp} />}
      {(!isStartScreen && application && !isSubmitSuccess) &&
        <div className={styles.container}>
          <nav>
            <ApplicationNav appId={appId} applicationSections={appType && appType.sections} currentSection={appSection} />
          </nav>
          <main>
            <ApplicationTitle type={application.typeDesc} company={application.companyInfo.name} />
            {state.isLoaded && getAppSection()}
            {submitError && <Label className='mtLg' isError title={submitError} />}
            <Controls
              className='mtXL mbXL'
              cancelText='Back'
              submitText={submitText}
              showSubmit={showSubmit}
              submitColor={isSubmitSection}
              hideCancel={hideCancel}
              onCancelClick={handleBackClick}
              isSubmitDisabled={isSubmitSection && isSubmitting}
              onSubmitClick={isSubmitSection ? submitOnly ? submitApp : handleSubmitApp : handleNextClick} />
          </main>
          {isSubmitting && <ProcessingOverlay submitError={submitError} />}
          <div className={styles.rightRail}>
            <GlobalActions onSaveClick={saveApplication} />
          </div>
        </div>}
      {(application && isSubmitSuccess) && <div className={styles.submittedContainer}>
        <div className={styles.appTitle}>
          <ApplicationTitle type={application.typeDesc} company={application.companyInfo.name} />
        </div>
        <Title className={styles.title} title='Thank you.' />
        <div className={styles.confirmation}>
          <FontAwesomeIcon className={styles.icon} size='2x' icon={['far', 'check-circle']} />
          <div>
            The {application.typeDesc} Application for {application.companyInfo.name} was submitted successfully on {moment().tz(timezones.NEW_YORK).format('MMMM D, YYYY')}
          </div>
        </div>
        <p>
          We will begin our review soon and an analyst will reach out to the Primary Contact identified in the application for next steps.
        </p>
        <a href={`/files/application/${appId}`} target='_blank' rel='noopener noreferrer'>
          <Entity title={`Your Completed ${application.typeDesc} Application`} icon='file' subTitle='View' />
        </a>
        <a href={`/files/application/${appId}/agreement`} target='_blank' rel='noopener noreferrer'>
          <Entity className='mtMed' title='Your Issuer Services Agreement' icon='file' subTitle='View' />
        </a>
        {showPaymentReceipt && <a href={`/files/application/${appId}/receipt`} target='_blank' rel='noopener noreferrer'>
          <Entity className='mtMed' title={`Receipt for ${application.typeDesc} Application Payment`} icon='file' subTitle='View' />
        </a>}
        <div className='mtXL'>
          <Link to={routes.MY_APPLICATIONS}>
            Go to My Applications <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
          </Link>
        </div>
      </div>}
    </div>
  );
};

ApplicationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string,
      applicationCard: PropTypes.string
    })
  })
};

export default withRouter(ApplicationPage);
