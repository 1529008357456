import React from 'react';
import { Svg, G, Path, Polygon } from '@react-pdf/renderer';

const LOGO_DNS = () => (
  <Svg viewBox='0 0 500 36.00299'>
    <G id='Components'>
      <G id='OTCID' fill='#FFFFFF' fill-rule='nonzero'>
        <Polygon className='cls-1' points='68.43607 19.2944 62.04395 19.2944 63.06869 16.4783 69.46086 16.4783 68.43607 19.2944' />
        <Polygon className='cls-1' points='74.8281 19.2944 68.43598 19.2944 69.46072 16.4783 75.85288 16.4783 74.8281 19.2944' />
        <Polygon className='cls-1' points='81.22002 19.2944 74.82791 19.2944 75.85265 16.4783 82.24477 16.4783 81.22002 19.2944' />

        <Path className='cls-1' d='M49.83029,3.2762v13.31061h6.04571V3.2762h1.75112c7.51298,0,9.377,1.30078,9.77369,5.42551h1.91986V.621h-32.88038v8.08071h1.92098c.33973-4.12473,2.65633-5.42551,9.71674-5.42551h1.75229Z' />
        <Path className='cls-1' d='M19.38103,33.33455c-6.09263,0-11.98525-4.94274-12.78204-14.04034H.01019c.64034,10.12235,8.82269,16.69443,18.69476,16.69443,10.03408,0,18.0667-6.57203,18.69363-16.69443h-6.51507c-.25477,7.78122-4.38955,14.04035-11.50248,14.04035' />
        <Path className='cls-1' d='M18.08236,2.65521c7.99576,0,12.0724,7.06601,12.72171,13.93198h6.58437C36.65866,6.21109,28.10975,0,18.8177,0,10.0352,0,.73642,5.41546,0,16.58714h6.51843c.18552-6.23791,3.34803-13.93198,11.56398-13.93198' />
        <Path className='cls-1' d='M55.87576,28.69911v-9.40495h-6.04571v9.40495c0,3.22064-.16987,4.63539-4.40856,4.63539h-1.58018v2.03164h18.02203v-2.03164h-1.58238c-3.84089,0-4.4052-.90516-4.4052-4.63539' />
        <Path className='cls-1' d='M88.47039,2.65521c8.36119,0,11.18845,4.80194,11.75169,8.47516h1.92098V2.20262c-2.54119-.67721-8.13544-2.20262-13.61571-2.20262-12.43451,0-18.42097,7.3085-19.86142,14.52984h6.44689c1.0225-7.06153,5.30704-11.87464,13.35757-11.87464' />
        <Path className='cls-1' d='M88.58347,35.98817c5.36849,0,9.77706-1.12979,13.55982-2.14674v-9.04064h-1.92098c-.95993,5.31041-4.5203,8.53329-11.75169,8.53329-8.5031,0-12.43783-5.31821-13.37318-11.983h-6.45694c1.34658,7.02241,7.05704,14.63709,19.94301,14.63709' />
        <Path className='cls-1' d='M114.50383,36.00299h-4.87311V.09725h4.87311v35.90574Z' />
        <Path className='cls-1' d='M149.09692,10.89812v-.00023c-.92091-2.16402-2.24295-4.07384-3.93052-5.67631-1.68528-1.59971-3.72061-2.86792-6.04846-3.76874-2.326-.89946-4.91259-1.35559-7.68767-1.35559h-12.60549v27.62918h4.87311V4.67077h7.73239c2.12888,0,4.08936.35048,5.82622,1.04138,1.73276.69067,3.21863,1.64812,4.41562,2.84577,1.1984,1.19816,2.13935,2.62628,2.79699,4.24403.65711,1.61957.99026,3.38518.99026,5.24826v.09996c0,1.86374-.33313,3.62934-.98983,5.24779-.65758,1.61752-1.59766,3.03634-2.79512,4.21735-1.19699,1.18195-2.6824,2.12272-4.41426,2.79628-1.73822.67562-3.69962,1.01811-5.82986,1.01811h-7.12817v.00481h-5.47733v4.56848h12.6055c2.77778,0,5.36485-.46478,7.69038-1.38139,2.32782-.91797,4.36225-2.19389,6.04571-3.79294,1.68756-1.602,3.01008-3.52051,3.93099-5.70252.91956-2.17954,1.38546-4.5603,1.38546-7.07592v-.09996c0-2.51492-.46636-4.88774-1.38592-7.05218v.00005Z' />
      </G>
    </G>
  </Svg>
);
export default LOGO_DNS;
