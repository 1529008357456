import React, { useContext, useState } from 'react';
import moment from 'moment-timezone';
import parsePhoneNumber from 'libphonenumber-js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateMFAPreference, updateUserAttributes } from 'aws-amplify/auth';
import { AuthContext } from '../../context/Auth.context';
import { NotificationsContext } from '../../context/Notifications.context';
import { errors, routes, titles, urls, phoneNumberRegex } from '../../constants';
import { RadioGroup } from 'devextreme-react';
import DialogBox from '../../components/DialogBox';
import Label from '../../components/Label';
import PhoneInput from '../../components/PhoneInput';
import Title from '../../components/Title';
import Entity from '../../components/Entity';
import Footnote from '../../components/Footnote';
import styles from './ManageMFA.module.scss';

export const checkPhoneNum = (phoneNumber) => {
  return phoneNumberRegex.test(phoneNumber);
};

const mfaOptions = [
  {
    id: 'SMS',
    value: 'Text'
  },
  {
    id: 'EMAIL',
    value: 'Email'
  }
];

const ManageMFA = () => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const isIQUser = authState.iq;
  const isAWSUser = authState.aws;
  const userPreferredMfa = isAWSUser ? authState.preferred : null;
  const userPhoneNumber = authState.phoneNumber;
  const userEmail = authState.email;
  const [isChangeSuccess, setChangeSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectedMfa, setSelectedMfa] = useState(userPreferredMfa);
  const parsedNumber = userPhoneNumber && parsePhoneNumber(userPhoneNumber);
  const country = parsedNumber ? parsedNumber.country : 'us';
  const [editedPhone, setEditedPhone] = useState(userPhoneNumber);
  const [editDialog, toggleEditDialog] = useState(false);

  const handleInputChage = value => {
    setEditedPhone(`${value}`);
  };

  const handleEditClick = e => {
    e.stopPropagation();
    if (error) setError(false);
    if (phoneError) setPhoneError(false);
    if (isChangeSuccess) setChangeSuccess(false);
    toggleEditDialog(true);
  };

  const handleUpdatePhone = async () => {
    const validPhone = checkPhoneNum(editedPhone);
    if (!validPhone) {
      setPhoneError(errors.INVALID_PHONE_NUMBER);
      return;
    }

    const id = new Date().getTime();

    try {
      await updateUserAttributes({
        userAttributes: {
          phone_number: editedPhone
        }
      });

      setChangeSuccess(true);
      authDispatch({
        type: 'UPDATE_USER',
        payload: editedPhone
      });

      dispatchNotification({
        type: 'ADD_NOTIFICATION',
        payload: {
          id: id,
          description: `Your settings have been saved. ${moment().format('MMMM D, YYYY')}`,
          type: 'save'
        }
      });

      setTimeout(() => {
        dispatchNotification({
          type: 'REMOVE_NOTIFICATION',
          id: id
        });
      }, 3000);
    } catch (error) {
      setError(error.message || errors.TRY_AGAIN);
    }

    toggleEditDialog(false);
  };

  const renderIQ = () => <>
    <p>
      Your account is associated with OTCIQ credentials. To manage your PIN click the link below to be taken to the OTCIQ Profile page.
    </p>
    <Label className='mtXL'>
      <b>
        You need to go to OTCIQ to manage your PIN. <a href={urls.IQ_PROFILE} target='_blank' rel='noopener noreferrer'>Click Here to Go to OTCIQ Profile</a>
      </b>
    </Label>
  </>;

  const renderRadioOptions = data => {
    if (data.id === 'SMS') {
      return <div className={styles.optionContainer}>
        <div>{data.value}</div>
        <div className={styles.details}>
          <Entity title={userPhoneNumber} isComplete={isChangeSuccess} icon={isChangeSuccess ? 'check' : undefined} subTitle='Edit' size='small' onClick={handleEditClick} />
          {editDialog &&
            <DialogBox
              title={titles.EDIT_PHONE_NUMBER}
              onCancelClick={() => toggleEditDialog(false)}
              onSubmitClick={handleUpdatePhone}>
              <PhoneInput
                countryCodeValue={country}
                phoneValue={editedPhone}
                onValueChanged={handleInputChage} />
              {phoneError && <Label title={phoneError} isError />}
            </DialogBox>
          }
          <Footnote text='Please make sure this number can receive text messages.' />
        </div>
      </div>;
    }

    if (data.id === 'EMAIL') {
      return <div className={styles.optionContainer}>
        <div>{data.value}</div>
        <div className={styles.details}>
          <div>{userEmail}</div>
          <Footnote text='This email address is your username and cannot be changed.' />
        </div>
      </div>;
    }
  };

  const onMfaChanged = async item => {
    setSelectedMfa(item.value);
    setDisabled(true);

    const id = new Date().getTime();

    try {
      await updateMFAPreference({ [item.value.toLowerCase()]: 'PREFERRED' });

      dispatchNotification({
        type: 'ADD_NOTIFICATION',
        payload: {
          id: id,
          description: `Your preferred authentication method has been saved. ${moment().format('MMMM D, YYYY')}`,
          type: 'save'
        }
      });

      setTimeout(() => {
        dispatchNotification({
          type: 'REMOVE_NOTIFICATION',
          id: id
        });
      }, 3000);
    } catch (err) {
      setError(error.message || errors.TRY_AGAIN);
    }

    setDisabled(false);
  };

  const renderAWS = () => <>
    <div>
      <Title title='Preferred Authentication Method' type='h2' />
      <p className='mbXL'>
        How would you like us to deliver your PIN? Gateway requires authentication each time you login.
      </p>
      <RadioGroup
        id='mfa-options'
        valueExpr='id'
        displayExpr='value'
        items={mfaOptions}
        value={selectedMfa}
        disabled={disabled}
        itemRender={renderRadioOptions}
        onValueChanged={onMfaChanged} />
    </div>
    {error && <Label title={error} isError />}
  </>;

  return <div className={styles.container}>
    {isIQUser && renderIQ()}
    {isAWSUser && renderAWS()}

    <div className='mtXXL'>
      <Link to={routes.MY_APPLICATIONS}>
        <FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back to My Applications
      </Link>
    </div>
  </div>;
};

ManageMFA.propTypes = {

};

export default ManageMFA;
