import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { Tooltip } from 'devextreme-react/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypesContext } from '../../context/Types.context';
import { NotificationsContext } from '../../context/Notifications.context';
import { ApplicationContext } from '../../context/Application.context';
import { appStates, titles } from '../../constants';
import ApplicationOwners from '../../containers/ApplicationOwners';
import HelpSection from '../HelpSection';
import SlidePanel from '../SlidePanel';
import styles from './GlobalActions.module.scss';

const APP_TYPE = 'app-type';
const OWNERS = 'owners';
const SAVE = 'save';
const HELP = 'help';
const INCOMPLETES = 'incompletes';

const GlobalActions = ({ onSaveClick }) => {
  const [slidePanel, setSlidePanel] = useState(false);
  const [typesState] = useContext(TypesContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [state, dispatch] = useContext(ApplicationContext);
  const [isHover, setHover] = useState(null);
  const application = state.application;
  const appType = typesState.appTypes.find(type => type.id === application.typeId);
  const isOTCQX = application.typeId === 1 || application.typeId === 2 || application.typeId === 3;
  const isOTCQB = application.typeId === 4;
  const isDNS = application.typeId === 5;
  const hasIncompleteNotification = notificationState.notifications.find(n => n.type === 'incompletes');
  const hasSaveNotification = notificationState.notifications.find(n => n.type === 'save');
  const isReadOnly = state.readOnly;
  const disableOwners = state.application.status !== appStates.IN_PROGRESS && state.application.status !== appStates.SUBMITTED;
  const disableSave = hasSaveNotification || isReadOnly;
  const isOwnersSlide = slidePanel === OWNERS;
  const isHelpSlide = slidePanel === HELP;
  let slideTitle;
  if (isOwnersSlide) slideTitle = titles.APP_PARTICIPANTS;
  if (isHelpSlide) slideTitle = titles.HELP;

  const handleOwnersClick = () => !disableOwners && setSlidePanel(OWNERS);

  const handleSaveClick = () => {
    !disableSave && onSaveClick();
  };

  const handleIncompleteClick = () => {
    if (state.showIncompletes) {
      hasIncompleteNotification && dispatchNotification({
        type: 'REMOVE_NOTIFICATION',
        id: hasIncompleteNotification.id
      });

      dispatch({
        type: 'SET_INCOMPLETES',
        payload: false
      });
    } else {
      dispatchNotification({
        type: 'ADD_NOTIFICATION',
        payload: {
          id: new Date().getTime(),
          description: 'You are now able to view all Incomplete Data Fields',
          type: 'incompletes'
        }
      });

      dispatch({
        type: 'SET_INCOMPLETES',
        payload: true
      });
    }
  };

  return (
    <div className={styles.container}>
      <div id={APP_TYPE} className={cn(styles.item, styles.application, {
        [styles.green]: isOTCQX,
        [styles.orange]: isOTCQB,
        [styles.otcidGray]: isDNS
      })}
        onMouseEnter={() => setHover(APP_TYPE)}
        onMouseLeave={() => setHover(null)}>
        <img className={styles.logo} src={`/logos/${appType.logo}`} alt={appType.name} />
        <Tooltip
          target={`#${APP_TYPE}`}
          visible={isHover && isHover === APP_TYPE}
          position='left'
          hideOnOutsideClick={false}
        >
          {application.typeDesc} Application for {application.companyInfo.name}
        </Tooltip>
      </div>
      <div id={OWNERS} className={cn(styles.item, {
        [styles.inactive]: disableOwners
      })}
        onClick={handleOwnersClick}
        onMouseEnter={() => setHover(OWNERS)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'user-friends']} size='lg' />
        <Tooltip
          target={`#${OWNERS}`}
          visible={isHover && isHover === OWNERS}
          position='left'
          hideOnOutsideClick={false}
        >
          {titles.APP_PARTICIPANTS}
        </Tooltip>
      </div>
      <div id={SAVE} className={cn(styles.item, {
        [styles.inactive]: disableSave
      })}
        onClick={handleSaveClick}
        onMouseEnter={() => setHover(SAVE)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'save']} size='lg' />
        <Tooltip
          target={`#${SAVE}`}
          visible={isHover && isHover === SAVE}
          position='left'
          hideOnOutsideClick={false}
        >
          Save Application
        </Tooltip>
      </div>
      <div id={HELP} className={styles.item}
        onClick={() => setSlidePanel(HELP)}
        onMouseEnter={() => setHover(HELP)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'question-circle']} size='lg' />
        <Tooltip
          target={`#${HELP}`}
          visible={isHover && isHover === HELP}
          position='left'
          hideOnOutsideClick={false}
        >
          Help Section
        </Tooltip>
      </div>
      <div id={INCOMPLETES} className={cn(styles.item, {
        [styles.errorActive]: state.showIncompletes
      })}
        onClick={handleIncompleteClick}
        onMouseEnter={() => setHover(INCOMPLETES)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} size='lg' />
        <Tooltip
          target={`#${INCOMPLETES}`}
          visible={isHover && isHover === INCOMPLETES}
          position='left'
          hideOnOutsideClick={false}
        >
          Show Incomplete Fields
        </Tooltip>
      </div>
      <SlidePanel
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={slideTitle}>
        {isOwnersSlide && <ApplicationOwners />}
        {isHelpSlide && <HelpSection />}
      </SlidePanel>
    </div>
  );
};

export default GlobalActions;
