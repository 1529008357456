import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { updatePassword } from 'aws-amplify/auth';
import { AuthContext } from '../../context/Auth.context';
import { errors, routes, urls } from '../../constants';
import TextBox from '../../components/TextBox';
import Controls from '../../components/Controls';
import Label from '../../components/Label';
import PasswordRequirements from '../../components/PasswordRequirements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import styles from './ChangePassword.module.scss';

const userObj = {
  oldPassword: '',
  newPassword: ''
};

const ChangePassword = () => {
  const [authState] = useContext(AuthContext);
  const [user, setUser] = useState({ ...userObj, ...authState });
  const [isChangeSuccess, setChangeSuccess] = useState(false);
  const [changeError, setChangeError] = useState(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [passwordError, setPasswordError] = useState(false);
  const isIQUser = user.iq;
  const isAWSUser = user.aws;

  const passwordButton = {
    icon: passwordMode === 'text' ? '/images/icons/eye-regular.svg' : '/images/icons/eye-slash-regular.svg',
    type: 'default',
    onClick: () => {
      passwordMode === 'text' ? setPasswordMode('password') : setPasswordMode('text');
    }
  };

  const onValueChanged = (value, field) => {
    setUser(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handlePasswordChange = async () => {
    if (changeError) setChangeError(null);

    try {
      await updatePassword({
        oldPassword: user.oldPassword,
        newPassword: user.newPassword
      });
      setChangeSuccess(true);
    } catch (err) {
      setChangeError(err.message || errors.TRY_AGAIN);
    }
  };

  const renderIQ = () => <>
    <p>
      Your account is associated with OTCIQ credentials. To change your password click the link below to be taken to the OTCIQ Profile page.
    </p>
    <Label className='mtXL'>
      <b>
        You need to go to OTCIQ to change your password. <a href={urls.IQ_PROFILE} target='_blank' rel='noopener noreferrer'>Click Here to Go to OTCIQ Profile</a>
      </b>
    </Label>
  </>;

  const renderAWS = () => <>
    <div className={styles.form}>
      <div className='mtMed'>
        <TextBox
          label='Old Password'
          placeholder='Enter current password'
          name='oldPassword'
          value={user.oldPassword}
          mode={passwordMode}
          // isRequired={showRequired}
          valueChangeEvent='keyup'
          onValueChanged={e => onValueChanged(e.value, 'oldPassword')}>
          <TextBoxButton
            name='password'
            location='after'
            options={passwordButton}
          />
        </TextBox>
      </div>
      <div className='mtMed'>
        <TextBox
          label='New Password'
          placeholder='At least 14 characters'
          name='newPassword'
          value={user.newPassword}
          mode={passwordMode}
          // isRequired={showRequired}
          valueChangeEvent='keyup'
          onValueChanged={e => onValueChanged(e.value, 'newPassword')}>
          <TextBoxButton
            name='password'
            location='after'
            options={passwordButton}
          />
        </TextBox>
      </div>
      <PasswordRequirements className='mtMed' value={user.newPassword} isError={passwordError} />
      {changeError && <Label className='mtLg' title={changeError} isError />}
      <div className='mtXXL'>
        <Controls
          submitText='Submit'
          hideCancel
          onSubmitClick={handlePasswordChange} />
      </div>
    </div>
  </>;

  return <div className={styles.container}>
    {isIQUser && renderIQ()}
    {(isAWSUser && !isChangeSuccess) && renderAWS()}
    {(isAWSUser && isChangeSuccess) && <Label title='Your password has been successfully changed.' />}
    <div className='mtXXL'>
      <Link to={routes.MY_APPLICATIONS}>
        <FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back to My Applications
      </Link>
    </div>
  </div>;
};

ChangePassword.propTypes = {

};

export default ChangePassword;
