import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { sessionStorage } from 'aws-amplify/utils';
import { getCurrentAwsUser, awsSignout } from '../api/auth';
import { getConfig } from '../api/common';
import { storage } from '../constants';

import { fetchMFAPreference } from 'aws-amplify/auth';

export const AuthContext = createContext();

export const AuthConsumer = AuthContext.Consumer;

const COOKIE_EXP = 0.416667;

const initialState = {
  isReady: false,
  isAuthenticated: false,
  firstName: null,
  lastName: null,
  fullName: null,
  email: null,
  initials: null,
  timedOut: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_READY':
      return {
        ...state,
        isReady: true
      };
    case 'SET_USER':
      window.sessionStorage.setItem(storage.AP_USER, JSON.stringify(action.payload));
      if (action.payload) {
        action.payload.email = action.payload.email?.toLowerCase();
      }
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload,
        session: action.session,
        timedOut: false
      };
    case 'UPDATE_USER':
      const loggedInUser = window.sessionStorage.getItem(storage.AP_USER);
      const updatedUser = JSON.parse(loggedInUser);
      updatedUser.phoneNumber = action.payload;
      window.sessionStorage.setItem(storage.AP_USER, JSON.stringify(updatedUser));
      return {
        ...state,
        phoneNumber: action.payload
      };
    case 'SIGN_OUT_USER':
      if (state.aws) {
        awsSignout();
      }
      window.sessionStorage.removeItem(storage.AP_USER);
      return {
        ...initialState,
        isReady: true,
        timedOut: action.payload
      };
    default:
      throw new Error('Unknown action type');
  }
};

export const AuthProvider = (props) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const loggedInUser = window.sessionStorage.getItem(storage.AP_USER);

  const setAuthReady = () =>
    authDispatch({
      type: 'SET_READY'
    });

  useEffect(() => {
    getConfig()
      .then(async (data) => {
        Amplify.configure({
          Auth: {
            Cognito: {
              region: data.awsRegion,
              identityPoolId: data.awsIdentityPoolId,
              userPoolId: data.awsUserPool,
              userPoolClientId: data.awsClientId,
              loginWith: {
                email: true
              },
              signUpVerificationMethod: 'code',
              userAttributes: {
                email: {
                  required: true
                },
                phoneNumber: {
                  required: true
                }
              },
              multifactor: {
                mode: 'REQUIRED',
                email: true,
                sms: true
              },
              cookieStorage: {
                domain: window.location.hostname,
                expires: COOKIE_EXP,
                secure: false
              }
            }
          },
          Storage: {
            AWSS3: {
              region: data.awsRegion,
              bucket: data.awsS3PdfBucket
            },
          },
        });

        cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

        if (loggedInUser) {
          const userObj = JSON.parse(loggedInUser);

          if (userObj.aws) {
            const { enabled, preferred } = await fetchMFAPreference();
            getCurrentAwsUser()
              .then((user) => {
                userObj.preferred = preferred;
                userObj.enabled = enabled;
                authDispatch({
                  type: 'SET_USER',
                  payload: userObj,
                  session: user
                });
                setAuthReady();
              })
              .catch(() => {
                authDispatch({
                  type: 'SIGN_OUT_USER'
                });
                setAuthReady();
              });
          } else {
            authDispatch({
              type: 'SET_USER',
              payload: userObj
            });
            setAuthReady();
          }
        } else {
          setAuthReady();
        }
      })
      .catch((e) => {
        console.error('Error retrieving auth configurations.', e);
      });
  }, [loggedInUser]);

  return (
    <AuthContext.Provider value={[authState, authDispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
