import React, { useEffect, useReducer, createContext } from 'react';
import { storage, errors, disabledUploadDocTypes } from '../constants';
import { getTypes, getCountryCodes } from '../api/common';
export const TypesContext = createContext();

const initialState = {
  isLoaded: false
};

const map = new Map([
  ['OTCQX_US', {
    name: 'U.S. Application',
    logo: 'OTC_QX_logo--white.svg'
  }],
  ['OTCQX_BANKS', {
    name: 'Banks Application',
    logo: 'OTC_QX_logo--white.svg'
  }],
  ['OTCQX_INTL', {
    name: 'International Application',
    logo: 'OTC_QX_logo--white.svg'
  }],
  ['OTCQB', {
    name: 'QB Application',
    logo: 'OTC_QB_logo--white.svg'
  }],
  ['DNS', {
    name: 'OTCID - Disclosure & News Service',
    logo: 'OTCID_logo--white.svg'
  }]
]);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TYPES':
      return {
        ...state,
        isLoaded: true,
        ...action.payload
      };
    case 'SET_COUNTRY_CODES':
      const countriesLoaded = (action.payload && action.payload.countries) || [];
      const countryPhoneCodeMap = new Map(countriesLoaded.map(obj => [obj.id, obj.phoneCode]));
      return {
        ...state,
        codes: action.payload,
        countryPhoneCodeMap: countryPhoneCodeMap
      };
    case 'SET_TYPES_ERROR':
      return {
        ...state,
        error: <>Technical error. {errors.TRY_AGAIN}</>
      };
    default:
      throw new Error();
  }
};

export const TypesContextProvider = props => {
  const [typesState, dispatchTypes] = useReducer(reducer, initialState);

  const loadTypes = () => getTypes().then(data => {
    const appTypes = data.appTypes.map(type => {
      return { ...type, ...map.get(type.typeId) };
    });

    const uploadDocTypes = data.docTypes ? data.docTypes.filter(docType => !disabledUploadDocTypes.includes(docType.typeId)) : [];

    dispatchTypes({
      type: 'SET_TYPES',
      payload: { ...data, appTypes, uploadDocTypes }
    });
  }).catch(() => dispatchTypes({
    type: 'SET_TYPES_ERROR'
  })
  );

  const loadCodes = () => {
    const currentDate = new Date();
    const storedCodes = window.localStorage.getItem(storage.COUNTRY_CODES);
    const storedTime = window.localStorage.getItem(storage.COUNTRY_CODES_TIME);
    if (storedCodes && storedTime && storedTime > currentDate.getTime()) {
      dispatchTypes({
        type: 'SET_COUNTRY_CODES',
        payload: JSON.parse(storedCodes)
      });
    } else {
      getCountryCodes().then(data => {
        if (!data) return;
        const countryList = data.countries;
        const USA = countryList && countryList.find(country => country.id === 'US');
        if (USA) countryList.unshift(USA);
        const expireDate = currentDate.setDate(currentDate.getDate() + 100);
        const codes = { ...data, countries: countryList };
        window.localStorage.setItem(storage.COUNTRY_CODES, JSON.stringify(codes));
        window.localStorage.setItem(storage.COUNTRY_CODES_TIME, expireDate);
        dispatchTypes({
          type: 'SET_COUNTRY_CODES',
          payload: codes
        });
      }).catch(e => console.log('error', e));
    }
  };

  useEffect(() => {
    loadTypes();
    loadCodes();
  }, []);

  return (
    <TypesContext.Provider value={[typesState, dispatchTypes]}>
      {props.children}
    </TypesContext.Provider>
  );
};
